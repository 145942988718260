import React from 'react'

export default function Loading() {
  return (
     
    <div className='flex h-10  space-x-2 justify-center items-center z-50 '>
    
    <div className='h-2 w-2 bg-gray-500 rounded-full animate-bounce [animation-delay:-0.3s]'></div>
  <div className='h-2 w-2 bg-gray-500 rounded-full animate-bounce [animation-delay:-0.15s]'></div>
  <div className='h-2 w-2 bg-gray-500 rounded-full animate-bounce'></div>
</div>
  )
}
