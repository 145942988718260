
// export function handleGroup(sortedReviews) {
            
//                      return  Object.groupBy(sortedReviews, (review)=> {
//                             return review.day
//                         })

// }
export function handleGroup(sortedReviews) {
    if (!sortedReviews || sortedReviews.length === 0) {
      return {}; // return an empty object if sortedReviews is falsy or empty
    }
  
    return sortedReviews.reduce((groups, review) => {
      const day = review.day;
      if (!groups[day]) {
        groups[day] = []; // create an array for the day if it doesn't exist
      }
      groups[day].push(review); // add the review to the corresponding day's array
      return groups;
    }, {});
  }