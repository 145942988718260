import React from 'react'

export default function Terms({setShowTerms}) {

  return (
    <div className='  font-lato text-xs h-[20rem] p-4 overflow-scroll bg-white border-2 rounded-md mx-4 leading-5'>


<h4 className='text-lg font-bold'>
Privacy Policy for GreatWall App
    </h4>
    <br></br>
Effective Date as date user signs on this App
<br></br>
<br></br>
Thank you for using the GreatWall mobile application ("GreatWall" or the "App"). At Aide Solutions , we are committed to protecting your privacy and ensuring the security of your personal information. This Privacy Policy outlines how we collect, use, disclose, and protect the information you provide when you use our App.
<br></br><br></br>
By accessing or using the GreatWall App, you agree to the terms of this Privacy Policy. If you do not agree with this Privacy Policy, please do not use the App.
<br></br><br></br>
<b>Information We Collect</b>
<br></br><br></br>
<ol className='list-decimal flex flex-col gap-2'>

<li>
   <b>Personal Information:</b> When you sign up for a GreatWall account, we may collect personal information such as your name, email address, phone number, and any other information you choose to provide.
   </li>
   <li>
<b>Usage Information:</b> We may collect information about how you use the GreatWall App, including your interactions with the App, the content you view, and the features you access.
</li>
<li>
<b>Device Information:</b> We may collect information about your device, including device type, operating system, unique device identifiers, and mobile network information.
</li>
<li>
<b>Location Information:</b> With your consent, we may collect information about your location when you use certain features of the App.
</li>
</ol>
<br></br>
<b>How We Use Your Information</b>
<br></br>
<br></br>
<ol className='list-decimal flex flex-col gap-2'>

<li>


<b>To Provide and Improve the App:</b> We use the information we collect to provide and improve the GreatWall App, including developing new features and functionalities, and optimizing user experience.
</li>
<li>
<b>To Communicate with You:</b> We may use your contact information to communicate with you about your account, updates to the App, and promotional offers.
</li>
<li>
<b>To Personalize Your Experience:</b> We may use the information we collect to personalize your experience with the GreatWall App, including providing personalized content and recommendations.
</li>
<li>
<b>To Ensure Security: </b>We may use your information to detect and prevent fraud, unauthorized access, and other security issues.
</li>
</ol>
<br></br>

<b>Information Sharing</b>
<br></br>
<br></br>

<b>We may share your information in the following circumstances:</b>
<br></br><br></br>
<ol className='list-decimal flex flex-col gap-2'>

<li>


<b>With Third-Party Service Providers:</b> We may share your information with third-party service providers who help us operate the GreatWall App, such as hosting providers, analytics providers, and customer support providers.
</li>
<li>
<b>With Your Consent:</b> We may share your information with third parties with your consent.
</li>
<li>
<b>For Legal Reasons:</b> We may share your information if we believe it is necessary to comply with applicable laws, regulations, or legal processes, or to protect the rights, property, or safety of GreatWall, our users, or others.
</li>
</ol>
<br></br>

<b>Data Retention</b>
<br></br>
<br></br>
We will retain your information for as long as necessary to fulfill the purposes outlined in this Privacy Policy unless a longer retention period is required or permitted by law.
<br></br>
<br></br>

<b>Security</b>
<br></br><br></br>
We take the security of your information seriously and take reasonable measures to protect it from unauthorized access, disclosure, alteration, or destruction.
<br></br><br></br>

<b>Changes to this Privacy Policy</b>
<br></br>
<br></br>
We may update this Privacy Policy from time to time. If we make any material changes, we will notify you by posting the updated Privacy Policy on the GreatWall App or by other means.
<br></br><br></br>
<b>Contact Us</b>
<br></br><br></br>
If you have any questions or concerns about this Privacy Policy or our privacy practices, please contact us at sales@aide-solutions.com.
<br></br>
<br></br>
By using the GreatWall App, you acknowledge that you have read and understood this Privacy Policy and agree to the collection, use, and disclosure of your information as described herein.

<div className='w-full flex justify-center'>
<button onClick={()=> setShowTerms(false)} className='p-2 bg-blue-500 text-white rounded-lg'>Back to Form</button>
    
    </div>      
    </div>
  )
}
